import '../stylesheets/application.scss'
import smoothScroll from 'smoothscroll-polyfill';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/collapse';

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

smoothScroll.polyfill();

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)